<template>
  <div class="download">
    <div class="warp">
      <img class="logo" :src="show_cow_oly" />
      <div class="download-btn" @click="downloadApp">
        <img :src="show_niu_oly" alt="" class="img-btn" />
        <div class="finger_box">
          <img src="http://cdn.9kd.com/kdnet/kd_download_finger.png" alt="" class="finger" />
        </div>
      </div>
    </div>
    <!-- 微信/QQ内打开外部浏览器引导弹窗 -->
    <van-overlay :show="showGuidePage" z-index="99999">
      <div class="foot-guide">
        <span class="step step-1">1 点击右上角</span>
        <span class="step step-2">2 选择在浏览器中打开</span>
        <img class="img-arrow" src="https://cdn.9kd.com/kdnet/d92f5b8a79cc484396cbb6fafcbac9fe.png" />
      </div>
    </van-overlay>
  </div>
</template>

<script>
import Bus from '../../utils/bus'
export default {
  data() {
    return {
      showGuidePage: false,
      link: '',
      hash: window.location.hash,
      isiOS: false
    }
  },
  created() {
    this.$route.query.pc && this.downloadApp()
  },
  computed: {
    show_cow_oly() {
      if (location.hash === '#vx_grounds') {
        return 'http://cdn.9kd.com/kdnet/kd_download_bg_new.png'
      } else if (location.hash === '#vx_official') {
        return 'http://cdn.9kd.com/kdnet/kd_download_bg_vx_official.png'
      }else {
        return 'http://cdn.9kd.com/kdnet/kd_download_bg_new1.png'
      }
    },
    show_niu_oly() {
      if (location.hash === '#vx_grounds') {
        return 'http://cdn.9kd.com/kdnet/kd_download_btn_new.png'
      } else {
        return 'http://cdn.9kd.com/kdnet/kd_download_btn_new1.png'
      }
    }
  },
  async mounted() {
    this.isiOS = !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
    if (this.$route.query.isWeixin) {
      if (navigator.userAgent.indexOf('MicroMessenger') >= 0 || navigator.userAgent.match(/\sQQ/i) == ' qq') {
        if(!this.isiOS){
          this.showGuidePage = true
        }

        // setTimeout(() => {
        //   this.showGuidePage = false
        // }, 3000)
      } else {
        setTimeout(() => {
          location.href = this.$route.query.link
        }, 500)
      }
    } else {
      if (navigator.userAgent.indexOf('MicroMessenger') >= 0 || navigator.userAgent.match(/\sQQ/i) == ' qq') {
        if(!this.isiOS){
          this.showGuidePage = true;
        }

        // setTimeout(() => {
        //   this.showGuidePage = false
        // }, 3000)
      }
    }
    Bus.$emit(Bus.SHOW_DOWN_FLOATING, false)
    Bus.$emit(Bus.SHOW_LOOP_FLOATING, false)
  },
  beforeDestroy() {
    Bus.$emit(Bus.SHOW_LOOP_FLOATING, true)
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1)
    },
    async downloadApp() {
      let u = navigator.userAgent;
      let isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
      if (!isiOS) {
        let { data: res } = await this.$api.getUpdateInfo()
        if (res.code !== 200) return this.$toast.fail('链接错误')
        // 如果hash值有效，全部下载的都是正式环境的包
        let hash = location.hash.replace('#', '')
        if (hash && ['ad01', 'ad02', 'ad03', 'ad04', 'ad05','backup01', 'backup02', 'vx_grounds', 'vx_moments', 'vx_official'].includes(hash)) {
          window.location.href = 'https://kdnet-prod-app-package.oss-cn-shanghai.aliyuncs.com/kdnet_' + hash + '_4.3.0_build_206.apk'
        } else {
          window.location.href = res.data.url
        }
        await this.$api.countQRCodeDownload()
      } else {
        Bus.$emit(Bus.SHOW_OPEN_DIALOG, { show: true });
        // window.location.href = 'https://apps.apple.com/cn/app/%E5%87%AF%E8%BF%AA%E8%B5%84%E8%AE%AF/id1533642383'
      }
    },
  },
}
</script>

<style lang="less">
img {
  display: block;
}
.download {
  font-family: PingFang SC Bold, PingFang SC Bold-Bold;
  .title {
    font-size: 20px;
    font-weight: 500;
    color: #2d2d2d;
    line-height: 9px;
    letter-spacing: -1px;
  }
  .log {
    color: #2d2d2d;
    font-size: 26px;
  }
}
.warp {
  position: relative;
  width: 100%;
  height: 100%;
  margin-bottom: 20px;
  background-color: #fff;
  text-align: center;
  font-size: 15px;
  .logo {
    display: block;
    width: 100%;
    height: 100%;
  }
  .short_btn {
    position: absolute;
    bottom: 7.5%;
    left: 0;
    width: 100%;
    height: 100px;
    background: url(https://cdn.9kd.com/kdnet/short01.gif) no-repeat;
    background-size: 100%;
    p {
      width: 100%;
      position: absolute;
      bottom: 0px;
      text-align: center;
      color: rgba(245, 242, 242, 0.3);
    }
  }
  .download-btn {
    position: absolute;
    bottom: 10%;
    width: 100%;
    .img-btn {
      display: block;
      width: 174px;
      margin: 0 auto;
    }
    .finger_box {
      position: absolute;
      width: 62px;
      animation: topLeftToBottomRight 0.4s ease-in-out infinite alternate;
      .finger {
        width: 100%;
        display: block;
      }
    }
  }
}
</style>
<style>
@keyframes topLeftToBottomRight {
  from {
    top: 18%;
    right: 21%;
  }
  to {
    top: 40%;
    right: 18%;
  }
}
</style>
